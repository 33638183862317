<template>
  <div>

  <!--FOOTER-->
  <footer class="uk-section uk-section-small uk-section-muted" id="footer">
    <div class="uk-container">
      <div class="uk-width-1-1@m uk-flex uk-flex-center">
          <div class="uk-flex uk-flex-middle">
            <img src="../../assets/footer.png" alt="" class="uk-align-center" width="300" style="">
          </div>
      </div>
    </div>
  </footer>
  <!--/FOOTER-->
    <!-- menu off canvas -->
    <div id="nav-offcanvas" uk-offcanvas="overlay: true">
      <aside class="uk-offcanvas-bar uk-padding-remove">
        <div class="uk-card uk-card-default uk-card-small tm-shadow-remove">
          <nav class="uk-card-small uk-card-body">
            <ul class="uk-nav-default uk-nav-parent-icon uk-list-divider" uk-nav style="padding-left: 15px">
              <li class="uk-parent">
                <a href="#">
                  Registration <span class="uk-margin-xsmall-left"
                                 uk-icon="icon: chevron-down; ratio: .75;"></span>
                </a>
                <ul class="uk-nav-sub uk-list-divider">
                  <li v-if="$store.getters.isLoggedIn" v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN'">
                    <router-link title="OrganizationQuestion" tag="a" :to="{name: 'professorlist',params: {
                                        id: 0}}">
                      Professors
                    </router-link>
                  </li>
                  <li>
                    <router-link tag="a" :to="{name: 'studentlist',params:{id:0}}">Students
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link tag="a" :to="{ name: 'organizcategory', params: { pagetype: 'main' }}">
                  Category
                </router-link>
              </li>

              <li class="uk-parent">
                 <a href="#">
                    Question <span class="uk-margin-xsmall-left"
                                   uk-icon="icon: chevron-down; ratio: .75;"></span>
                 </a>
                <ul class="uk-nav-sub uk-list-divider">
                  <li>
                    <span class="uk-icon" @click="createQuestion()">{{$t("organization.orgaNav.quesRegTest")}}</span>
                  </li>
                  <li>
                    <router-link title="OrganizationQuestion" tag="a" :to="{name: 'OrganizationQuestion',params: {
                                        questionId: 0}}">
                      Excel upload
                    </router-link>
                  </li>
                  <li>
                    <router-link tag="a" :to="{name: 'OrganizationQuestionList'}">Question list
                    </router-link>
                  </li>
                  <li>
                    <router-link tag="a" :to="{name: 'OrganizationQuestionPurchased'}">Question Purchased
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="uk-parent">
                <a href="#" class="uk-text-capitalize">
                  {{$t("organization.orgaNav.exam")}} <span class="uk-margin-xsmall-left"
                                 uk-icon="icon: chevron-down; ratio: .75;"></span>
                </a>
                <ul class="uk-nav-sub uk-list-divider">
                  <li>
                    <router-link title="OrganizationExamRegister" tag="a"
                                 :to="{name: 'OrganizationExamRegister',params: { examId: 0}}">
                      {{$t("organization.orgaNav.eReg")}}
                    </router-link>
                  </li>
                  <li>
                    <router-link tag="a"  :to="{name: 'OrganizationExamList'}">
                      <span class="uk-icon">{{$t("organization.orgaNav.eList")}}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link tag="a"  :to="{name: 'OrganizationSurveyAdd'}">
                      <span class="uk-icon">{{$t("organization.orgaNav.sList")}}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link tag="a"  :to="{name: 'SurveyResultList'}">
                      <span class="uk-icon">Survey results</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link tag="a"  :to="{name: 'OrganizationExamResultView'}">
                  {{$t("organization.orgaNav.examAnaliz")}}
                </router-link>
              </li>

              <li class="uk-parent">
                <a href="#">
                  {{$t("organization.orgaNav.ubtMarket")}}
                  <span class="uk-margin-xsmall-left"
                        uk-icon="icon: chevron-down; ratio: .75;"></span>
                </a>
                <ul class="uk-nav-sub uk-list-divider">
                  <li>
                    <router-link title="data market" tag="a" :to="{name: 'datamarket'}">
                      <span class="uk-icon">{{$t("organization.orgaNav.ubtMData")}}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link title="question market" tag="a" :to="{name: 'questionmarket'}">
                      <span class="uk-icon">{{$t("organization.orgaNav.ubtMQues")}}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>

          <nav class="uk-card-body">
            <ul class="uk-iconnav uk-flex-center">
              <li>
                <a href="#" title="Facebook" uk-icon="facebook"></a>
              </li>
              <li>
                <a href="#" title="Twitter" uk-icon="twitter"></a>
              </li>
              <li>
                <a href="#" title="YouTube" uk-icon="youtube"></a>
              </li>
              <li>
                <a href="#" title="Instagram" uk-icon="instagram"></a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>

    <!-- menu off canvas end  -->
    <div id="cart-offcanvas" uk-offcanvas="overlay: true;flip: true">
      <aside class="uk-offcanvas-bar uk-padding-remove">
        <div
          class="uk-card uk-card-default uk-card-small uk-height-1-1 uk-flex uk-flex-column tm-shadow-remove"
        >
          <header class="uk-card-header uk-flex uk-flex-middle">
            <div class="uk-grid-small uk-flex-1" uk-grid>
              <div class="uk-width-expand">
                <h3 style="color:#666">Cart</h3>
              </div>
              <a
                class="uk-offcanvas-close uk-text-emphasis"
                style="color:#666 !important"

                uk-close
              ></a>
            </div>
          </header>
          <div class="uk-card-body uk-overflow-auto">
            <ul class="uk-list uk-list-divider" v-if="cartItemList.length > 0">
              <app-cart-item
                v-for="cartItem in cartItemList"
                :cartItem="cartItem"
                :key="cartItem.id"
              ></app-cart-item>
            </ul>

            <div v-else>
              <div class="uk-alert-primary" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <p class="uk-text-center">Your cart is empty.</p>
              </div>
            </div>
          </div>
          <footer class="uk-card-footer">
            <div class="uk-grid-small" uk-grid>
              <div class="uk-width-expand uk-text-muted uk-h4">Total Points</div>
<!--              <div class="uk-h4 uk-text-bolder">{{ cartValue|currency }} Point</div>-->
            </div>
            <div
              class="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m uk-margin-small"
              uk-grid
            >
              <div>
                <router-link
                  class="uk-button uk-button-default uk-margin-small uk-width-1-1"
                  style="background: #e5e5e5;color: #434241;"
                  title="view cart"
                  tag="a"
                  :to="{name: 'ocart'}"
                >
                  view
                  cart
                </router-link>
              </div>
              <div>
                <a
                  style="background: #0e6dcd;color: #fff;"
                  class="uk-button uk-button-primary uk-margin-small uk-width-1-1"
                  href="checkout.html"
                >checkout</a>
              </div>
            </div>
          </footer>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CartItem from './Cart/CartItem.vue'
import store from '@/store/store'

export default {
  computed: {
    ...mapGetters(['cartItemList', 'isLoggedIn', 'currentUser', 'cartValue'])
  },
  data() {
    return {
      localPath: '/ubtexam/teacher/qbank',
      }
  },
  components: {
    appCartItem: CartItem
  },
  methods: {
    createQuestion(){
      this.$setCookie('utoken', store.getters.getToken);
      this.$setCookie('urole', store.getters.currentUser.authorities[0].authority);
      this.$setCookie('uorganizId', store.getters.currentUser.organizs[0].organizId);
      let lang = this.$i18n.locale== "kr" ? "ko" : this.$i18n.locale;
      window.open(this.localPath + "?lang="+ lang)
    },
    ...mapActions([
      'saveShoppingCart',
      'addMessage',
      'saveToTransaction',
      'clearCart'
    ]),
    checkValidCart (itemList, prodList) {
      let isValid = true
      let message = ''
      itemList.map(item => {
        for (let prodIdx = 0; prodIdx < prodList.length; prodIdx++) {
          if (prodList[prodIdx].id === item.id) {
            if (prodList[prodIdx].quantity < item.quantity) {
              message = `Only ${prodList[prodIdx].quantity} ${
                item.title
              } available in stock`
              isValid = false
              return
            }
            break
          }
        }
      })
      return {
        isValid,
        message
      }
    },
    saveShoppingCartLocal () {
      if (this.isLoggedIn) {
        const { isValid, message } = this.checkValidCart(
          this.cartItemList,
          this.products
        )
        if (isValid) {
          this.saveShoppingCart({
            cartItemList: this.cartItemList,
            uid: this.currentUser.uid
          }).then(() => {
            this.addMessage({
              messageClass: 'success',
              message: 'Your shopping cart is saved successfully'
            })
            this.$router.push('/')
          })
        } else {
          this.addMessage({
            messageClass: 'danger',
            message: message
          })
        }
      } else {
        this.addMessage({
          messageClass: 'warning',
          message: 'Please login to save your cart'
        })
      }
    },
    checkout () {
      if (this.isLoggedIn) {
        if (!this.cartItemList || this.cartItemList.length === 0) {
          this.addMessage({
            messageClass: 'warning',
            message: 'Your cart is empty!'
          })
          return
        }
        const { isValid, message } = this.checkValidCart(
          this.cartItemList,
          this.products
        )
        if (isValid) {
          this.saveToTransaction({
            cartItemList: this.cartItemList,
            uid: this.currentUser.uid
          }).then(() => {
            this.addMessage({
              messageClass: 'success',
              message: 'Your order has been successfully processed!'
            })
            this.saveShoppingCart({
              cartItemList: [],
              uid: this.currentUser.uid
            })
            this.clearCart()
            this.$router.push('/')
          })
        } else {
          this.addMessage({
            messageClass: 'danger',
            message: message
          })
        }
      } else {
        this.addMessage({
          messageClass: 'warning',
          message: 'Please login to checkout'
        })
      }
    }
  }
}
</script>

<style scoped>
  .uk-section-small{
    background: #fff;
  }
  .uk-card-body{
    padding: 20px 20px;
  }
</style>
