<template>
  <li class="uk-visible-toggle">
    <article>
      <div class="uk-grid-small" uk-grid>
        <div class="uk-width-1-4">
          <div class="tm-ratio tm-ratio-4-3">
            <router-link
              v-if="cartItem.type === 'data'"
              class="tm-media-box"
              :title="cartItem.title"
              tag="a"
              :to="{
              name: 'answer',
              params: {
                answerId: cartItem.id
              }
            }"
            >
              <figure class="tm-media-box-wrap">
                <!-- <img :src="cartItem.imageurl" :alt="cartItem.title"> -->
                <vue-plyr data-width="500" data-height="300" v-if="cartItem.datatype == 2">
                  <video poster :src="cartItem.imageurl">
                    <source :src="cartItem.imageurl" type="video/mp4" size="720">
                    <source :src="cartItem.imageurl" type="video/mp4" size="1080">
                    <track
                      kind="captions"
                      label="English"
                      srclang="kr"

                      default
                    >
                  </video>
                </vue-plyr>
                <vue-plyr data-width="500" data-height="300" v-else-if="cartItem.datatype == 3">
                  <audio>
                    <source :src="cartItem.imageurl" type="audio/mp3">
                    <source :src="cartItem.imageurl" type="audio/ogg">
                  </audio>
                </vue-plyr>
                <img
                  v-else
                  class="lazy"
                  data-width="500"
                  style="width:100%"
                  :data-src="cartItem.imageurl"
                  data-height="300"
                  data-uk-img
                  alt
                >
              </figure>
            </router-link>
            <router-link
              v-else
              class="tm-media-box"
              :title="cartItem.title"
              tag="a"
              :to="{
              name: 'question',
              params: {
                questionId: cartItem.id
              }
            }"
            >
              <figure class="tm-media-box-wrap">
                <div class="uk-position-relative uk-visible-toggle uk-light" uk-slideshow>
                  <ul class="uk-slideshow-items">
                    <img class="uk-border-circle" width="35" :src="cartItem.organizLogo" :alt="cartItem.organizName"/>
                    <li v-for="(itemmedia, index) in cartItem.question_datas" :key="index">
                      <vue-plyr
                        data-width="500"
                        data-height="300"
                        v-if="itemmedia.media.mediatype === 'video'"
                      >
                        <video poster :src="`/uploadingDir/`+itemmedia.media.filename">
                          <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                  type="video/mp4" size="720">
                          <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                  type="video/mp4" size="1080">
                          <track
                            kind="captions"
                            label="English"
                            srclang="kr"

                            default
                          >
                        </video>
                      </vue-plyr>
                      <vue-plyr
                        data-width="500"
                        data-height="300"
                        v-else-if="itemmedia.media.mediatype === 'audio'"
                      >
                        <audio>
                          <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                  type="audio/mp3">
                          <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                  type="audio/ogg">
                        </audio>
                      </vue-plyr>

                      <img
                        v-else
                        class="lazy"
                        data-width="500"
                        style="width:100%"
                        :data-src="`/uploadingDir/`+itemmedia.media.filename"
                        data-height="300"
                        data-uk-img
                        alt
                      >
                    </li>
                  </ul>
                </div>
              </figure>
            </router-link>
          </div>
        </div>
        <div class="uk-width-expand">
          <!--<div style="color: #0e6dcd" class="uk-text-meta uk-text-xsmall uk-text-bold">sdfsdf</div>-->
          <router-link
            v-if="cartItem.type === 'data'"
            class="uk-link-heading uk-text-small"
            :title="cartItem.title"
            tag="a"
            :to="{
              name: 'answer',
              params: {
                answerId: cartItem.id
              }
            }"
          >{{cartItem.title}}
          </router-link>
          <router-link
            v-else
            class="uk-link-heading uk-text-small"
            :title="cartItem.title"
            tag="a"
            :to="{
              name: 'question',
              params: {
                questionId: cartItem.id
              }
            }"
          ><span v-html="cartItem.title"></span></router-link>

          <div class="uk-margin-xsmall uk-grid-small uk-flex-middle" uk-grid>
<!--            <div class="uk-text-bolder uk-text-small uk-text-danger">{{ subtotal|currency }} Point</div>-->
            <div class="uk-text-bolder uk-text-small uk-text-danger">{{ subtotal }} Point</div>
            <div
              class="uk-text-meta uk-text-xsmall"
            >{{cartItem.quantity}} ×
<!--              {{ cartItem.price|currency }}-->
              {{ cartItem.price }}
            </div>
          </div>
        </div>
        <div>
          <a
            @click="removeItem"
            class="uk-icon-link uk-text-danger uk-invisible-hover"
            href="#"
            uk-icon="icon: close; ratio: .75"
            uk-tooltip="Remove"
          ></a>
        </div>
      </div>
    </article>
  </li>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    props: ['cartItem'],
    computed: {
      subtotal() {
        return this.cartItem.quantity * this.cartItem.price
      },
      shortDescription() {
        let value = ""
        if (this.cartItem.type !== 'data') {
          value = this.cartItem.question
        } else {
          value = this.cartItem.description
        }
        if (value && value.length > 100) {
          return value.substring(0, 100) + '...'
        } else {
          return value
        }
      }
    },
    methods: {
      ...mapActions(['removeItemInCart']),
      removeItem() {
        const vm = this
        this.removeItemInCart({
          item: vm.cartItem
        })
      }
    }
  }
</script>
<style>
  .uk-card-body {
    padding: 20px 20px;
  }
</style>
